<template>
    <div id="LatestJudgement" class="m-5 pb-5 table-data">
        <section>


            <div class="columns is-justify-content-center search-section">
                <div class="column is-6">
                 <b-input class="input-brder-search" placeholder="Enter any 4 Char in Appellant" icon-right="magnify" rounded></b-input>
                 </div>
               <!--  <b-tabs type="is-toggle-rounded">
                    <b-tab-item label="Appellant">
                        <b-field class="mt-3">
                            <b-input placeholder="Enter any 4 Char in Appellant" icon-right="magnify" rounded></b-input>
                        </b-field>
                    </b-tab-item>
                    <b-tab-item label="Respondent">
                          <b-field class="mt-3">
                            <b-input placeholder="Enter any 4 Char in Respondent" icon-right="magnify"></b-input>
                        </b-field>
                    </b-tab-item>
                    <b-tab-item label="Citation">
                          <b-field class="mt-3">
                            <b-input placeholder="Enter any 4 Char in Citation" icon-right="magnify"></b-input>
                        </b-field>
                    </b-tab-item>
                    <b-tab-item label="Word">
                          <b-field class="mt-3">
                            <b-input placeholder="Enter any 4 Char in Word" icon-right="magnify"></b-input>
                        </b-field>
                    </b-tab-item>
                    <b-tab-item label="Date">
                          <b-field class="mt-3">
                            <b-input placeholder="Enter any 4 Char in Date" icon-right="magnify"></b-input>
                        </b-field>
                    </b-tab-item>
                </b-tabs> -->
            </div>



          <!--   <div class="columns">

                <div class="column is-8 is-offset-2-desktop">
                    <b-field label="Select Search Type">
                        <b-select placeholder="Appellant" expanded rounded>
                            <option value="Appellant">Appellant</option>
                            <option value="Respondent">Respondent</option>
                            <option value="Citation">Citation</option>
                            <option value="Word">Word</option>
                            <option value="Date">Date</option>
                        </b-select>
                    </b-field>
                </div>

            </div> -->

        </section>
    </div>
</template>
<script>
    export default {
        name: 'Appellant',


    }
</script>